import * as React from "react"
import Layout from "../components/layout"

// markup
const Impressum = () => {
  return (
    <Layout pageTitle="Impressum">
      <p>Apotheken Direkt</p>
      <p>Engel-Apotheke, Renate Koehler e. K.</p>
      <p>Rheinstr. 7-9</p>
      <p>64283 Darmstadt</p>
      <p>Deutschland</p>
      <h4>Kontaktdaten</h4>
      <p>Tel.: 06151 - 995830</p>
      <p>Fax: 06151 - 9958320</p>
      <p>E-Mail: <a href="mailto:info@apotheken-direkt.de">info@apotheken-direkt.de</a></p>
      <h4>Weitere Daten:</h4>
      <p>Zuständige Aufsichtsbehörde: RP Darmstadt</p>
      <p>Zuständige Apothekerkammer: Landesapothekerkammer Hessen</p>
      <p>Berufsordnung für Apotheker, Bundesland: Landesapothekerkammer Hessen</p>
      <p>Handelsregister: Amtsgericht Darmstadt, HRA 3002</p>
      <p>USt.-ID: DE254660918</p>
      <h4>Datenschutzbeauftragter:</h4>
      <p>Name: Axel Boos <a href="mailto:datenschutz@apotheken-direkt.de">datenschutz@apotheken-direkt.de</a></p>
      <h4>Hinweis zur EU-Online-Streitbeilegung:</h4>
      <p>Wir informieren Sie darüber, dass seitens der Europäischen Kommission eine Plattform zur Online-Streitbeilegung bereitgehalten wird. Diese erreichen Sie unter: <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>.</p>
      <h4>Hinweis nach §36 Verbraucherstreitbeilegungsgesetz:</h4>
      <p>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet.</p>
      <h4>Haftungshinweise</h4>
      <p>Die Inhalte der Website wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.</p>
      <p>Als Dienstanbieter ist der Inhaber gemäß § 7 TMG für eigene Inhalte auf dieser Website nach den allgemeinen Gesetzen verantwortlich. Es besteht jedoch keine Pflicht, die vom Nutzer übermittelten oder gespeicherten fremden Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Eine Verpflichtung zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleibt hiervon unberührt. Eine Haftung besteht ab dem Zeitpunkt der Kenntnisnahme von einer konkreten Rechtsverletzung. Bei Bekanntwerden von Rechtsverletzungen wird der Inhaber die entsprechenden Inhalte umgehend entfernen.</p>
      <p>Die Website enthält Links zu externen Websites Dritter, auf deren Inhalte der Inhaber keinen Einfluss hat. Der Inhaber übernimmt für diese fremden Inhalte keine Gewähr. Für diese fremden Inhalte ist der jeweilige Anbieter oder Betreiber der Website verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung durch den Inhaber auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Bei Bekanntwerden von Rechtsverletzungen wird der Inhaber derartige Links umgehend entfernen.</p>
      <p>Der Download oder die Speicherung von Inhalten der Website erfolgt auf eigenes Risiko. Der Inhaber übernimmt keine Haftung für Schäden des Nutzers, die aufgrund von Virenverseuchung oder sonstiger Schadhaftigkeit dieser Inhalte entstehen.</p>
    </Layout>
  )
}

export default Impressum
